/* Overriding element hover */
.optionContainer li:hover,
.optionContainer .highlight {
  background: #378805;
  color: #fff;
}
/* Ends overriding */

input[type='checkbox']:checked {
  background-color: #378805;
}
input[type='checkbox']:checked:after {
  content: '\2714';
  color:white;
  font-weight: bold;
}
input[type='checkbox']{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 18px !important;
  height: 18px !important;
  appearance:none;
  border-radius:10%;
  border: 2px solid rgb(0 0 0 / 15%);
  box-shadow: none;
  font-size: 1em;
}

input[type='radio']:checked {
  background-color: #378805;
}
input[type='radio']:checked:after {
  color:white;
  font-weight: bold;
}
input[type='radio']{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 18px !important;
  height: 18px !important;
  border-radius: 20px !important;
  margin-bottom: 3px;
  appearance:none;
  border-radius:10%;
  border: 2px solid rgb(0 0 0 / 15%);
  box-shadow: none;
  font-size: 1em;
}